import { useMemo, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { ContentContext } from './context';

export function useRoutePath() {
    const params = useParams();
    const location = useLocation();
    const path = useMemo(() => {
        const paramsArr = Object.entries(params);
        if (paramsArr.length > 0) {
            const pathArr = location.pathname.split('/');
            paramsArr.forEach(([key, val]) => {
                const i = pathArr.findIndex((v) => v === val);
                pathArr.splice(i, 1, `:${key}`);
            });
            return pathArr.join('/');
        }
        return location.pathname;
    }, [location.pathname, params]);

    return path;
}

export function useContentContext() {
    return useContext(ContentContext);
}

export default {};
