import React, { useEffect } from 'react';
import { Layout } from 'antd';

import Header from './Header';
import Sider from './Sider';
import Content from './Content';

import staffRole from '../../utils/staffRole';
import asyncRoutes, { asyncRouteLink, defaultAsyncRoute } from '../../router/routes/asyncRoutes';
import { PAGE_MAIN_ID } from '../../config';

import styles from './index.module.less';

const routeTree = [...asyncRoutes];

export default function Main() {
    useEffect(() => staffRole.fetchConfig(), []);

    return (
        <Layout id={PAGE_MAIN_ID} className={styles.main}>
            <Header />
            <Layout>
                <Sider routeTree={routeTree} routeLink={asyncRouteLink} />
                <Content routeTree={routeTree} routeLink={asyncRouteLink} defaultRoute={defaultAsyncRoute} />
            </Layout>
        </Layout>
    );
}
