import { baseService } from './services';

import { QRCodeType } from './types';

/**
 * 获取系统token
 * @param code 企微授权码
 * @returns
 */
export function getSystemToken(code: string) {
    return baseService.post<string>('/auth/token?loginType=2', { authCode: code });
}

/**
 * 通过账号获取系统token，仅用于开发测试
 * @param account 账号
 * @returns
 */
export function getSystemTokenByAccount(account: string) {
    return baseService.post<string>('/auth/token?loginType=4', { username: account, password: 123456 });
}

export interface UserInfoData {
    id: string;
    userType: 1 | 2 | 3 | 4; // 1:员工 2:企业管理 3:客户 4:系统
    name: string;
    phone: string;
    authorityList: string[];
    corporationId: string;
    corporationName: string;
    storeId: string;
    storeName: string;
    userId: string;
}
/**
 * 获取用户信息
 * @returns
 */
export function getUserInfo() {
    return baseService.get<UserInfoData>('/system/user');
}

/**
 * 上传图片
 * @param file 文件数据
 * @returns
 */
export function uploadImage(file: FormData) {
    return baseService.post('/file/upload/single', file, { loading: false });
}

/**
 * 同步管理员
 * @returns
 */
export function syncAdmin() {
    return baseService.get(`/corporation/syncAdmin`);
}

export interface WxAgentConfig {
    corpid: string;
    agentid: string;
    timestamp: number;
    nonceStr: string;
    signature: string;
}

/**
 * 获取微信agentConfig
 * @param {string} url 当前页面的url
 * @returns
 */
export function getWxAgentConfig(url: string) {
    return baseService.get<WxAgentConfig>(`/corporation/getAgentConfig`, { params: { url } });
}

/**
 * 获取微信授权url
 * @param {string} url 当前页面的url
 * @returns
 */
export function getWxAuthUrl(url: string) {
    return baseService.get<string>(`/ssoLogin/scrm`, { params: { redirectUri: url } });
}

/**
 * 获取微信商城小程序的appId
 * @returns
 */
export function getWxAppId() {
    return baseService.get<string>(`/corporationApplication/getCorporationAppid`, { loading: false });
}

interface WxQRCodeParams {
    appId: string;
    scene: string;
    page?: string;
    width?: number;
    envVersion?: 'develop' | 'trial' | 'release';
}

/**
 * 生成微信二维码
 * @param params
 * @returns
 */
export function createWxQrcode(params: WxQRCodeParams) {
    return baseService.post<string>(`/miniProgram/wxacodeUnlimit`, params);
}

export interface CalendarFestival {
    type: 1 | 2; // 1:节日 2:节气
    name: string;
    time: string;
}

/**
 * 获取月份日历节日
 * @param month
 * @returns
 */
export function getMonthCalendarFestival(month: string) {
    const params = { month };
    return baseService.get<CalendarFestival[]>(`campaignCalendar/queryFestivalList`, { params });
}

interface MiniappShortCodeParams {
    id: string; // 关联id， 活动id/商品id
    type: QRCodeType;
    staffId?: string; // 员工id
    invaildTime?: number;
    params?: {
        source: 1 | 2 | 3; // 1:推手任务 2:顾问分享 3:客户分享
        promotionTaskId?: string; // 推广任务id
        taskId?: string; // 后端生成短码时会添加此字段
        _sopId?: string; // 后端生成短码时会添加此字段
        [key: string]: any;
    };
}

/**
 * 生成小程序短码
 * @param params
 * @returns
 */
export function createMiniappShortCode(params: MiniappShortCodeParams) {
    const { params: pms, ...reset } = params;
    const _pms = {
        ...reset,
        params: pms ? JSON.stringify(pms) : undefined,
    };
    return baseService.get<string>(`/shortCode/create`, { params: _pms });
}

export default {};
