import React, { lazy } from 'react';
import {
    DashboardOutlined,
    FundProjectionScreenOutlined,
    TagOutlined,
    DeploymentUnitOutlined,
} from '@ant-design/icons';

import staffRole from '../../utils/staffRole';
import {
    ACTIVITY_RECEIVE_VOUCHER,
    CUSTOMER,
    SALER_MANAGE,
    STORE,
    ONLINE_GOODS,
    ACTIVITY_GROUP,
    ONLINE_ORDER,
    ACTIVITY_NEWCOMER,
    CUSTOMER_FILTER,
    ACTIVITY_ISSUE_VOUCHER,
    OFFLINE_GOODS,
    OFFLINE_ORDER,
    ACTIVITY_SPECIAL_OFFER,
    MATERIAL,
    TASK_CUSTOMER,
    TASK_GROUP,
    TASK_MOMENTS,
    CUSTOMER_GROUP,
    SERVICE,
    PRODUCT,
    ACTIVITY_CALENDAR,
    STAFF_NOTICE,
    ACTIVITY_CALENDAR_DRAFT,
    ACTIVITY_CALENDAR_MONTH,
    ARTICLE,
    SOP_CUSTOMER,
    SOP_CUSTOMER_CALENDAR,
    SOP_CUSTOMER_SETTING,
    EXTERNAL_LINK,
    FISSION,
    FISSION_INFO_PANEL,
    FISSION_DRAFT,
    FISSION_TASK,
    ROBOT,
} from '../routeId';

import type { RouteInfo } from '../types';

import { processRoute, findDefaultRoute, createRouteLink } from '../utils';

const asyncRoutes: RouteInfo[] = [
    {
        path: '/workbench',
        title: '工作台',
        icon: <DashboardOutlined />,
        component: lazy(() => import('../../pages/workbench')),
    },
    {
        id: ACTIVITY_CALENDAR,
        path: '/activity-calendar',
        title: '智能营销',
        icon: <FundProjectionScreenOutlined />,
        component: lazy(() => import('../../pages/activityCalendar/Year')),
        children: [
            {
                id: ACTIVITY_CALENDAR_MONTH,
                path: 'month',
                hidden: true,
                component: lazy(() => import('../../pages/activityCalendar/Month')),
            },
            {
                path: 'create/:month',
                hidden: true,
                component: lazy(() => import('../../pages/activityCalendar/Create')),
            },
            {
                id: ACTIVITY_CALENDAR_DRAFT,
                path: 'draft/:id',
                hidden: true,
                component: lazy(() => import('../../pages/activityCalendar/Draft')),
            },
        ],
    },
    {
        id: SOP_CUSTOMER,
        path: '/sop-customer',
        title: '智能销售',
        icon: <TagOutlined />,
        component: lazy(() => import('../../pages/sopCustomer/index')),
        children: [
            {
                id: SOP_CUSTOMER_CALENDAR,
                path: 'calendar',
                hidden: true,
                component: lazy(() => import('../../pages/sopCustomer/Calendar')),
            },
            {
                id: SOP_CUSTOMER_SETTING,
                path: 'setting',
                hidden: true,
                component: lazy(() => import('../../pages/sopCustomer/Setting')),
                children: [
                    {
                        path: 'action/:id',
                        hidden: true,
                        component: lazy(() => import('../../pages/sopCustomer/SettingAction')),
                    },
                ],
            },
        ],
    },
    {
        id: FISSION,
        path: '/fission',
        title: '智能分享',
        icon: <DeploymentUnitOutlined />,
        component: lazy(() => import('../../pages/fission/index')),
        children: [
            {
                id: FISSION_INFO_PANEL,
                path: 'info-panel',
                hidden: true,
                component: lazy(() => import('../../pages/fission/InfoPanel')),
            },
            {
                path: 'create',
                hidden: true,
                component: lazy(() => import('../../pages/fission/Create')),
            },
            {
                id: FISSION_DRAFT,
                path: 'draft/:id',
                hidden: true,
                component: lazy(() => import('../../pages/fission/Draft')),
            },
        ],
    },
    {
        id: FISSION_TASK,
        path: '/fission-task',
        title: '推广任务',
        hidden: true,
        component: lazy(() => import('../../pages/fissionTask/List')),
        children: [
            {
                path: 'invite/:id',
                hidden: true,
                component: lazy(() => import('../../pages/fissionTask/Invite')),
            },
            {
                path: 'other/:id',
                hidden: true,
                component: lazy(() => import('../../pages/fissionTask/Other')),
            },
            {
                path: 'invite-detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/fissionTask/InviteDetail')),
            },
            {
                path: 'invite-data/:id',
                hidden: true,
                component: lazy(() => import('../../pages/fissionTask/InviteData')),
            },
            {
                path: 'other-detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/fissionTask/OtherDetail')),
            },
            {
                path: 'other-data/:id',
                hidden: true,
                component: lazy(() => import('../../pages/fissionTask/OtherData')),
            },
        ],
    },
    {
        path: '/saler-config',
        title: '推广规则',
        hidden: true,
        component: lazy(() => import('../../pages/salerConfig')),
    },
    {
        id: SALER_MANAGE,
        path: '/saler-manage',
        title: '推广人员',
        hidden: true,
        component: lazy(() => import('../../pages/salerManage/List')),
        children: [
            {
                path: 'detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/salerManage/Detail')),
            },
        ],
    },
    {
        path: '/saler-mall',
        title: '兑换奖励',
        hidden: true,
        component: lazy(() => import('../../pages/salerMall')),
    },
    {
        path: '/saler-redeem-record',
        title: '兑换记录',
        hidden: true,
        component: lazy(() => import('../../pages/salerRedeemRecord')),
    },
    {
        path: '/report-day-consultant',
        title: `${staffRole.label.saler}每日业绩`,
        hidden: true,
        component: lazy(() => import('../../pages/dataReport/ReportDayConsultant')),
    },
    {
        path: '/report-month-consultant',
        title: `${staffRole.label.saler}月度业绩`,
        hidden: true,
        component: lazy(() => import('../../pages/dataReport/ReportMonthConsultant')),
    },
    {
        path: '/report-day-operator',
        title: `${staffRole.label.operator}每日业绩`,
        hidden: true,
        component: lazy(() => import('../../pages/dataReport/ReportDayOperator')),
    },
    {
        path: '/report-month-operator',
        title: `${staffRole.label.operator}月度业绩`,
        hidden: true,
        component: lazy(() => import('../../pages/dataReport/ReportMonthOperator')),
    },
    {
        path: '/report-store',
        title: '门店资源分析',
        hidden: true,
        component: lazy(() => import('../../pages/dataReport/ReportStore')),
    },
    {
        path: '/appoint-calendar',
        title: '客户预约看板',
        hidden: true,
        component: lazy(() => import('../../pages/appointCalendar')),
    },
    {
        id: CUSTOMER_FILTER,
        path: '/customer-filter',
        title: '营销客群',
        hidden: true,
        component: lazy(() => import('../../pages/customerFilter/List')),
        children: [
            {
                path: 'action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/customerFilter/Action')),
            },
            {
                path: 'detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/customerFilter/Detail')),
            },
        ],
    },
    {
        id: ACTIVITY_RECEIVE_VOUCHER,
        path: '/activity-receive-voucher',
        title: '限时抢券',
        hidden: true,
        component: lazy(() => import('../../pages/activityReceiveVoucher/List')),
        children: [
            {
                path: 'action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/activityReceiveVoucher/Action')),
            },
            {
                path: 'detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/activityReceiveVoucher/Detail')),
            },
            {
                path: 'data/:id',
                hidden: true,
                component: lazy(() => import('../../pages/activityReceiveVoucher/Data')),
            },
        ],
    },
    {
        id: ACTIVITY_GROUP,
        path: '/activity-group',
        title: '多人拼团',
        hidden: true,
        component: lazy(() => import('../../pages/activityGroup/List')),
        children: [
            {
                path: 'action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/activityGroup/Action')),
            },
            {
                path: 'detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/activityGroup/Detail')),
            },
            {
                path: 'data/:id',
                hidden: true,
                component: lazy(() => import('../../pages/activityGroup/Data')),
            },
        ],
    },
    {
        id: ACTIVITY_SPECIAL_OFFER,
        path: '/activity-special-offer',
        title: '限时特惠',
        hidden: true,
        component: lazy(() => import('../../pages/activitySpecialOffer/List')),
        children: [
            {
                path: 'action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/activitySpecialOffer/Action')),
            },
            {
                path: 'detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/activitySpecialOffer/Detail')),
            },
            {
                path: 'data/:id',
                hidden: true,
                component: lazy(() => import('../../pages/activitySpecialOffer/Data')),
            },
        ],
    },
    {
        id: ACTIVITY_NEWCOMER,
        path: '/activity-newcomer',
        title: '新人礼包',
        hidden: true,
        component: lazy(() => import('../../pages/activityNewcomer/List')),
        children: [
            {
                path: 'action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/activityNewcomer/Action')),
            },
            {
                path: 'detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/activityNewcomer/Detail')),
            },
        ],
    },
    {
        id: ACTIVITY_ISSUE_VOUCHER,
        path: '/activity-issue-voucher',
        title: '专享礼包',
        hidden: true,
        component: lazy(() => import('../../pages/activityIssueVoucher/List')),
        children: [
            {
                path: 'action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/activityIssueVoucher/Action')),
            },
            {
                path: 'detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/activityIssueVoucher/Detail')),
            },
            {
                path: 'data/:id',
                hidden: true,
                component: lazy(() => import('../../pages/activityIssueVoucher/Data')),
            },
        ],
    },
    {
        id: EXTERNAL_LINK,
        path: '/external-link',
        title: '外链管理',
        hidden: true,
        component: lazy(() => import('../../pages/externalLink/List')),
        children: [
            {
                path: 'action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/externalLink/Action')),
            },
            {
                path: 'detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/externalLink/Detail')),
            },
            {
                path: 'data/:id',
                hidden: true,
                component: lazy(() => import('../../pages/externalLink/Data')),
            },
        ],
    },
    {
        path: '/ai-record',
        title: 'AI消费记录',
        hidden: true,
        component: lazy(() => import('../../pages/aiRecord/index')),
    },
    {
        id: ONLINE_ORDER,
        path: '/online-order',
        title: '小程序订单',
        hidden: true,
        component: lazy(() => import('../../pages/onlineOrder/List')),
        children: [
            {
                path: 'detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/onlineOrder/Detail')),
            },
        ],
    },
    {
        path: '/online-refund',
        title: '小程序退单',
        hidden: true,
        component: lazy(() => import('../../pages/onlineRefund')),
    },
    {
        id: OFFLINE_ORDER,
        path: '/offline-order',
        title: '门店订单',
        hidden: true,
        component: lazy(() => import('../../pages/offlineOrder/List')),
        children: [
            {
                path: 'detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/offlineOrder/Detail')),
            },
        ],
    },
    {
        path: '/offline-refund',
        title: '门店退单',
        hidden: true,
        component: lazy(() => import('../../pages/offlineRefund')),
    },
    {
        path: '/record-redeem',
        title: '门店核销单',
        hidden: true,
        component: lazy(() => import('../../pages/voucherRedeemRecord')),
    },
    {
        path: '/record-consume-sheet',
        title: '门店操作开单',
        hidden: true,
        component: lazy(() => import('../../pages/consumeSheet')),
    },
    {
        path: '/record-consume',
        title: '操作记录',
        hidden: true,
        component: lazy(() => import('../../pages/consumeRecord')),
    },
    {
        path: '/vip-card',
        title: '会员卡管理',
        hidden: true,
        component: lazy(() => import('../../pages/vipCard')),
    },
    {
        id: CUSTOMER,
        path: '/customer',
        title: '客户管理',
        hidden: true,
        component: lazy(() => import('../../pages/customer/List')),
        children: [
            {
                path: 'detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/customer/Detail')),
            },
            {
                path: 'profile/:id',
                hidden: true,
                component: lazy(() => import('../../pages/customer/Profile')),
            },
        ],
    },
    {
        id: CUSTOMER_GROUP,
        path: '/customer-group',
        title: '客户群管理',
        hidden: true,
        component: lazy(() => import('../../pages/customerGroup/List')),
        children: [
            {
                path: 'detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/customerGroup/Detail')),
            },
        ],
    },
    {
        path: '/customer-journey',
        title: '客户旅程',
        hidden: true,
        component: lazy(() => import('../../pages/customerJourney')),
    },
    {
        path: '/custom-tag',
        title: '标签管理',
        hidden: true,
        component: lazy(() => import('../../pages/customTag')),
    },
    {
        path: '/material-public',
        title: '公共素材库',
        hidden: true,
        component: lazy(() => import('../../pages/material/PublicList')),
    },
    {
        id: MATERIAL,
        path: '/material',
        title: '自定义素材库',
        hidden: true,
        component: lazy(() => import('../../pages/material/List')),
        children: [
            {
                path: 'action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/material/Action')),
            },
            {
                path: 'detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/material/Detail')),
            },
        ],
    },
    {
        id: STAFF_NOTICE,
        path: '/staff-notice',
        title: '内部公告',
        hidden: true,
        component: lazy(() => import('../../pages/staffNotice/List')),
        children: [
            {
                path: 'action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/staffNotice/Action')),
            },
            {
                path: 'detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/staffNotice/Detail')),
            },
            {
                path: 'data/:id',
                hidden: true,
                component: lazy(() => import('../../pages/staffNotice/Data')),
            },
        ],
    },
    {
        id: TASK_CUSTOMER,
        path: '/task-customer',
        query: 'type=1',
        title: '客户群发',
        hidden: true,
        component: lazy(() => import('../../pages/task/List')),
        children: [
            {
                path: 'action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/task/Action')),
            },
            {
                path: 'detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/task/Detail')),
            },
            {
                path: 'data/:id',
                hidden: true,
                component: lazy(() => import('../../pages/task/DataCustomer')),
            },
            {
                path: 'plan-action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/task/PlanAction')),
            },
            {
                path: 'fission-action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/task/FissionAction')),
            },
        ],
    },
    {
        id: TASK_GROUP,
        path: '/task-group',
        query: 'type=2',
        title: '客户群群发',
        hidden: true,
        component: lazy(() => import('../../pages/task/List')),
        children: [
            {
                path: 'action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/task/Action')),
            },
            {
                path: 'detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/task/Detail')),
            },
            {
                path: 'data/:id',
                hidden: true,
                component: lazy(() => import('../../pages/task/DataGroup')),
            },
            {
                path: 'fission-action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/task/FissionAction')),
            },
        ],
    },
    {
        id: TASK_MOMENTS,
        path: '/task-moments',
        query: 'type=3',
        title: '朋友圈群发',
        hidden: true,
        component: lazy(() => import('../../pages/task/List')),
        children: [
            {
                path: 'action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/task/Action')),
            },
            {
                path: 'detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/task/Detail')),
            },
            {
                path: 'data/:id',
                hidden: true,
                component: lazy(() => import('../../pages/task/DataMoments')),
            },
            {
                path: 'plan-action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/task/PlanAction')),
            },
        ],
    },
    {
        path: '/mini-app-home-page',
        title: '首页装修',
        hidden: true,
        component: lazy(() => import('../../pages/miniAppHomePage')),
    },
    {
        path: '/online-category',
        title: '商品类目',
        hidden: true,
        component: lazy(() => import('../../pages/onlineCategory')),
    },
    {
        id: ONLINE_GOODS,
        path: '/online-goods',
        title: '商品管理',
        hidden: true,
        component: lazy(() => import('../../pages/onlineGoods/List')),
        children: [
            {
                path: 'action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/onlineGoods/Action')),
            },
            {
                path: 'detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/onlineGoods/Detail')),
            },
        ],
    },
    {
        id: ARTICLE,
        path: '/article',
        title: '内容管理',
        hidden: true,
        component: lazy(() => import('../../pages/article/List')),
        children: [
            {
                path: 'action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/article/Action')),
            },
            {
                path: 'data/:id',
                hidden: true,
                component: lazy(() => import('../../pages/article/Data')),
            },
        ],
    },
    {
        path: '/offline-category',
        title: '商品类目',
        hidden: true,
        component: lazy(() => import('../../pages/offlineCategory')),
    },
    {
        id: OFFLINE_GOODS,
        path: '/offline-goods',
        title: '商品管理',
        hidden: true,
        component: lazy(() => import('../../pages/offlineGoods/List')),
        children: [
            {
                path: 'action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/offlineGoods/Action')),
            },
            {
                path: 'detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/offlineGoods/Detail')),
            },
        ],
    },
    {
        path: '/consult-template',
        title: '咨询模板',
        hidden: true,
        component: lazy(() => import('../../pages/consultTemplate')),
    },
    {
        id: PRODUCT,
        path: '/product',
        title: '实体产品',
        hidden: true,
        component: lazy(() => import('../../pages/product/List')),
        children: [
            {
                path: 'action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/product/Action')),
            },
        ],
    },
    {
        id: SERVICE,
        path: '/service',
        title: '服务项目',
        hidden: true,
        component: lazy(() => import('../../pages/service/List')),
        children: [
            {
                path: 'action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/service/Action')),
            },
        ],
    },
    {
        path: '/brand',
        title: '企业品牌',
        hidden: true,
        component: lazy(() => import('../../pages/brand')),
    },
    {
        path: '/staff',
        title: '员工管理',
        hidden: true,
        component: lazy(() => import('../../pages/staff')),
    },
    {
        id: STORE,
        path: '/store',
        title: '门店管理',
        hidden: true,
        component: lazy(() => import('../../pages/store/List')),
        children: [
            {
                path: 'action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/store/Action')),
            },
            {
                path: 'detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/store/Detail')),
            },
        ],
    },
    {
        path: '/mini-app-agreement',
        title: '协议配置',
        hidden: true,
        component: lazy(() => import('../../pages/miniAppAgreement')),
    },
    {
        path: '/mini-app-customer-service',
        title: '客服配置',
        hidden: true,
        component: lazy(() => import('../../pages/miniAppCustomerService')),
    },
    {
        path: '/other-config',
        title: '规则配置',
        hidden: true,
        component: lazy(() => import('../../pages/otherConfig')),
    },
    {
        path: '/abnormal-staff',
        title: '异常员工',
        hidden: true,
        component: lazy(() => import('../../pages/abnormalStaff')),
    },
    {
        path: '/abnormal-customer',
        title: '异常客户',
        hidden: true,
        component: lazy(() => import('../../pages/abnormalCustomer')),
    },
    {
        id: ROBOT,
        path: '/robot',
        title: '机器人管理',
        hidden: true,
        component: lazy(() => import('../../pages/robot/List')),
        children: [
            {
                path: 'action/:id',
                hidden: true,
                component: lazy(() => import('../../pages/robot/Action')),
            },
            {
                path: 'detail/:id',
                hidden: true,
                component: lazy(() => import('../../pages/robot/Detail')),
            },
        ],
    },
    {
        path: '/sms-record',
        title: '短信消费记录',
        hidden: true,
        component: lazy(() => import('../../pages/smsRecord')),
    },
];

const newAsyncRoutes = processRoute(asyncRoutes);

export const asyncRouteLink = createRouteLink(newAsyncRoutes);

export const defaultAsyncRoute = findDefaultRoute(newAsyncRoutes);

export default newAsyncRoutes;
