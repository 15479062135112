import { getWxAgentConfig } from '../apis/system';

function config() {
    return new Promise<any>((resolve, reject) => {
        getWxAgentConfig(window.location.href).then((res) => {
            window.wx.agentConfig({
                ...res.data,
                jsApiList: ['selectExternalContact'],
                success(succ: any) {
                    resolve(succ);
                },
                fail(fail: any) {
                    reject(fail);
                },
            });
        });
    });
}

export default function useWxAgentConfig() {
    return config;
}
