import type { AxiosInstance } from 'axios';

import { API_BASE_URL } from '../config';
import { create } from './serviceCreator';

import { RequestConfig, Result } from './types';

const DEFAULT_CONFIG = {
    loading: true,
    token: true,
};

function createMethods(instance: AxiosInstance) {
    return {
        get<T = any>(url: string, config?: RequestConfig): Promise<Result<T>> {
            const _config = { ...DEFAULT_CONFIG, ...config };
            return instance.request({
                ..._config,
                method: 'get',
                url,
            });
        },
        delete<T = any>(url: string, config?: RequestConfig): Promise<Result<T>> {
            const _config = { ...DEFAULT_CONFIG, ...config };
            return instance.request({
                ..._config,
                method: 'delete',
                url,
            });
        },
        post<T = any>(url: string, data?: any, config?: RequestConfig): Promise<Result<T>> {
            const _config = { ...DEFAULT_CONFIG, ...config };
            return instance.request({
                ..._config,
                method: 'post',
                url,
                data,
            });
        },
        put<T = any>(url: string, data?: any, config?: RequestConfig): Promise<Result<T>> {
            const _config = { ...DEFAULT_CONFIG, ...config };
            return instance.request({
                ..._config,
                method: 'put',
                url,
                data,
            });
        },
    };
}

const base = create({
    baseURL: API_BASE_URL,
});
export const baseService = {
    instance: base.api,
    baseURL: base.baseURL,
    ...createMethods(base.api),
};

export default {
    baseService,
};
