import React, { useMemo } from 'react';
import { Navigate, useLocation, useMatches } from 'react-router-dom';

import token from '../utils/token';
import historyRecord from './historyRecord';

interface Props {
    redirectPath: string;
    children: JSX.Element;
}

export default function RouteGuard(props: Props) {
    const { redirectPath, children } = props;
    const matches = useMatches();
    const location = useLocation();
    const currentPath = location.pathname.endsWith('/')
        ? location.pathname.slice(0, location.pathname.length - 1)
        : location.pathname;
    // 是否是登录页
    const isLogin = useMemo(() => currentPath === '/login', [currentPath]);
    // 是否是内部登录页
    const isLoginTest = useMemo(() => currentPath === '/login-inner', [currentPath]);
    const hasToken = token.check();
    const currRouter = matches.find((match) => match.pathname === currentPath);

    // 缓存当前页历史记录中的下标
    if (currRouter) {
        historyRecord.set(currRouter.id, window.history.state.idx);
    }

    if (hasToken) {
        switch (true) {
            // 有token时，访问登录页就跳转通用重定向路由
            case isLogin:
                return <Navigate to={redirectPath} replace={true} />;
            default:
                break;
        }
    } else if (!isLogin && !isLoginTest) {
        // 排除内部登录页
        // == state={{ from: location }}可以实现跳转退登前页面的功能，但有缓存。。看实际需求加吧。
        return <Navigate to="/login" replace={true} />;
    }

    return children;
}
