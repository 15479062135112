import { useRef } from 'react';

export default function useInstance<T extends Record<string, any>>(selector?: T) {
    const instance = useRef<T>(selector || ({} as T));

    function setInstance(val: T) {
        Object.keys(val).forEach((key) => {
            const prop = key as keyof T;
            if (instance.current) {
                instance.current[prop] = val[prop];
            }
        });
        Object.setPrototypeOf(instance.current, Object.getPrototypeOf(val));
    }

    return [instance.current, setInstance] as const;
}
