import { createSlice } from '@reduxjs/toolkit';

import { STORAGE_PREFIX } from '../../config';

import { Locale } from '../../i18n/types';

const KEY = `${STORAGE_PREFIX}locale`;

const slice = createSlice({
    name: 'locale',
    initialState: (window.localStorage.getItem(KEY) as Locale) || 'zh',
    reducers: {
        toggleLocale(state, { payload }: { payload: Locale }) {
            window.localStorage.setItem(KEY, payload);
            return payload;
        },
    },
});

export const { toggleLocale } = slice.actions;

export default slice.reducer;
