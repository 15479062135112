import { createSlice } from '@reduxjs/toolkit';

import { STORAGE_PREFIX } from '../../config';

import { UserInfo } from '../types';

const KEY = `${STORAGE_PREFIX}userInfo`;
const info = window.localStorage.getItem(KEY);

const slice = createSlice({
    name: 'userInfo',
    initialState: info ? JSON.parse(info) : {},
    reducers: {
        updateUserInfo(state, { payload }: { payload: UserInfo }) {
            window.localStorage.setItem(KEY, JSON.stringify(payload));
            return payload;
        },
    },
});

export const { updateUserInfo } = slice.actions;

export default slice.reducer;
