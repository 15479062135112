import { STORAGE_PREFIX } from '../config';
import { getStaffRoleConfig, StaffRoleConfigVO } from '../apis/org';

interface LabelMap {
    manager: string; // 店长
    saler: string; // 销售
    operator: string; // 服务操作人
}

const KEY = `${STORAGE_PREFIX}StaffRole`;

class StaffRole {
    private roleConfig: StaffRoleConfigVO[];

    constructor() {
        const config = window.sessionStorage.getItem(KEY);
        this.roleConfig = config ? JSON.parse(config) : [];
    }

    fetchConfig() {
        getStaffRoleConfig().then((res) => {
            this.update(res.data);
        });
    }

    get config() {
        return this.roleConfig;
    }

    get label() {
        const label: LabelMap = {
            manager: '',
            saler: '',
            operator: '',
        };
        this.config.forEach((item) => {
            if (item.roleCode === 'ROLE_STORE_MANAGER') {
                label.manager = item.roleName;
            } else if (item.roleCode === 'ROLE_STAFF_COUNSELOR') {
                label.saler = item.roleName;
            } else if (item.roleCode === 'ROLE_SERVICE_OPERATOR') {
                label.operator = item.roleName;
            }
        });
        return label;
    }

    update(config: StaffRoleConfigVO[]) {
        this.roleConfig = config;
        window.sessionStorage.setItem(KEY, JSON.stringify(config));
    }
}

export default new StaffRole();
