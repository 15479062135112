import locale from './locale';
import userInfo from './userInfo';
import loading from './loading';
import salerConfig from './salerConfig';

export default {
    locale,
    userInfo,
    loading,
    salerConfig,
};
