import Login from '../../pages/login';
import LoginInner from '../../pages/loginInner';
import Page404 from '../../pages/page404';
import Main from '../../pages/main';

import type { RouteInfo } from '../types';

const constantRoutes: RouteInfo[] = [
    {
        path: '/login',
        component: Login,
    },
    {
        path: '/login-inner',
        component: LoginInner,
    },
    {
        path: '/404',
        component: Page404,
    },
    {
        path: `/`,
        component: Main,
        redirectRoute: true,
    },
];

export default constantRoutes;
