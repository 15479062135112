import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useMount } from 'ahooks';
import { Flex, Button, Tabs } from 'antd';

import { updateUserInfo } from '../../store/reducers/userInfo';
import { updateSalerConfig } from '../../store/reducers/salerConfig';
import { defaultAsyncRoute } from '../../router/routes/asyncRoutes';
import token from '../../utils/token';
import { getSystemToken, getUserInfo, getWxAuthUrl, getWxAppId } from '../../apis/system';
import { getSalerConfig } from '../../apis/saler';

import styles from './index.module.less';
import imgLogo from '../../assets/images/login__logo.png';
import imgWechat from '../../assets/images/login__wechat-icon.png';

export default function Login() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const [authUrl, setAuthUrl] = useState('');

    function logined(_token: string) {
        token.set(_token);
        Promise.all([getUserInfo(), getWxAppId(), getSalerConfig()]).then(([userInfo, appId, salerConfig]) => {
            const { name, corporationName, userId } = userInfo.data;
            dispatch(
                updateUserInfo({
                    name,
                    avatar: '',
                    enterpriseName: corporationName,
                    userId,
                    wxAppId: appId.data,
                }),
            );
            dispatch(updateSalerConfig(salerConfig.data));
            const from = location.state?.from || { pathname: '', search: '' };
            const redirect =
                `${from?.pathname}${from?.search}` ||
                `${defaultAsyncRoute.path}${defaultAsyncRoute.query ? `?${defaultAsyncRoute.query}` : ''}`;
            navigate(redirect, { replace: true });
        });
    }

    function handleScanLogin() {
        window.location.href = authUrl;
    }

    const LoginQRCode = (
        <div className={`flex ${styles['qrcode-wrap']}`} onClick={handleScanLogin}>
            <img className={styles.icon} src={imgWechat} alt="" />
            <span className={styles.label}>点击扫码登录</span>
        </div>
    );

    useMount(() => {
        const code = searchParams.get('code');
        const authCode = searchParams.get('auth_code');
        const paramCode = code || authCode;
        if (paramCode) {
            getSystemToken(paramCode).then((res) => {
                logined(res.data);
            });
        } else {
            getWxAuthUrl(window.location.href).then((res) => {
                setAuthUrl(res.data);
            });
        }
    });

    return (
        <div className={`flex ${styles.login}`}>
            <div className={`flex ${styles.main}`}>
                <div className={styles.banner}></div>
                <div className={styles.panel}>
                    <div className={`flex ${styles.header}`}>
                        <img src={imgLogo} alt="" className={styles.logo} />
                        <span className={styles.title}>{process.env.REACT_APP_NAME}</span>
                    </div>
                    <Tabs
                        defaultActiveKey="1"
                        centered={true}
                        items={[
                            {
                                label: '扫码登录',
                                key: 'qrCode',
                                children: LoginQRCode,
                            },
                        ]}
                    />
                </div>
            </div>
            <Flex justify="center" className={styles.footer}>
                <Button
                    type="link"
                    href="https://beian.miit.gov.cn"
                    target="_blank"
                    className={styles['filing-number']}
                >
                    浙ICP备2023000287号
                </Button>
            </Flex>
        </div>
    );
}
