import { STORAGE_PREFIX } from '../config';

class HistoryRecord {
    private storageKey = `${STORAGE_PREFIX}history`;

    private record: Record<string, number> = {};

    constructor() {
        const json = window.sessionStorage.getItem(this.storageKey);
        if (json) {
            const obj = JSON.parse(json);
            Object.keys(obj).forEach((key) => {
                this.record[key] = Number(obj[key]);
            });
        }
    }

    has(key: string) {
        return this.record[key] !== undefined;
    }

    get(key: string) {
        return this.has(key) ? this.record[key] : 0;
    }

    set(key: string, value: number) {
        this.record[key] = value;
        window.sessionStorage.setItem(this.storageKey, JSON.stringify(this.record));
    }

    remove(key: string) {
        Reflect.deleteProperty(this.record, key);
        window.sessionStorage.setItem(this.storageKey, JSON.stringify(this.record));
    }

    getDelta(key: string) {
        if (this.has(key)) {
            return this.get(key) - window.history.state.idx;
        }
        return -1;
    }
}

export default new HistoryRecord();
