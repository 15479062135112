import dayjs, { Dayjs } from 'dayjs';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';

type DisabledDate = DatePickerProps['disabledDate'];
type DisabledRangeTime = RangePickerProps['disabledTime'];
type DisabledTime = DatePickerProps['disabledTime'];
type Mode = 'date' | 'time' | 'rangeTime';
type DisabledFunction<T extends Mode> = T extends 'date'
    ? DisabledDate
    : T extends 'time'
    ? DisabledTime
    : T extends 'rangeTime'
    ? DisabledRangeTime
    : undefined;

const disabledDate: DisabledDate = (current: Dayjs) => current && current < dayjs().startOf('day');

const disabledRangeTime: DisabledRangeTime = (current: Dayjs, type?: 'start' | 'end') => {
    return {
        disabledHours: () => {
            if (current.startOf('day').isSame(dayjs().startOf('day'))) {
                return Array.from({ length: dayjs().hour() }, (_, i) => i);
            }
            return [];
        },
        disabledMinutes: () => {
            if (current.startOf('hour').isSame(dayjs().startOf('hour'))) {
                // 结束时间相较开始事件可选范围少一分钟
                const offset = type === 'start' ? 0 : 1;
                return Array.from({ length: dayjs().minute() + offset }, (_, i) => i);
            }
            return [];
        },
    };
};

const disabledTime: DisabledTime = (current: Dayjs) => {
    return {
        disabledHours: () => {
            if (current.startOf('day').isSame(dayjs().startOf('day'))) {
                return Array.from({ length: dayjs().hour() }, (_, i) => i);
            }
            return [];
        },
        disabledMinutes: () => {
            if (current.startOf('hour').isSame(dayjs().startOf('hour'))) {
                return Array.from({ length: dayjs().minute() + 1 }, (_, i) => i);
            }
            return [];
        },
    };
};

export default function useDisabledBeforeToday<T extends Mode>(mode: T): DisabledFunction<T> {
    if (mode === 'date') return disabledDate as DisabledFunction<T>;
    if (mode === 'time') return disabledTime as DisabledFunction<T>;
    if (mode === 'rangeTime') return disabledRangeTime as DisabledFunction<T>;
    return undefined as DisabledFunction<T>;
}
