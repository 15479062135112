import { STORAGE_PREFIX } from '../config';

const KEY = `${STORAGE_PREFIX}token`;

class Token {
    #value: string;

    constructor() {
        this.#value = window.sessionStorage.getItem(KEY) || '';
    }

    get() {
        return this.#value;
    }

    set(value: string) {
        this.#value = value;
        window.sessionStorage.setItem(KEY, value);
    }

    remove() {
        this.#value = '';
        window.sessionStorage.removeItem(KEY);
    }

    check() {
        return !!this.get();
    }
}

export default new Token();
