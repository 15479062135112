import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tabs, Form, Input, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { updateUserInfo } from '../../store/reducers/userInfo';
import { updateSalerConfig } from '../../store/reducers/salerConfig';
import { defaultAsyncRoute } from '../../router/routes/asyncRoutes';
import token from '../../utils/token';
import { getSystemTokenByAccount, getUserInfo, getWxAppId } from '../../apis/system';
import { getSalerConfig } from '../../apis/saler';

import styles from './index.module.less';
import imgLogo from '../../assets/images/login__logo.png';

interface FormModel {
    account: string;
}

// 用于开发测试登录
export default function LoginInner() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [form] = Form.useForm<FormModel>();

    function logined(_token: string) {
        token.set(_token);
        Promise.all([getUserInfo(), getWxAppId(), getSalerConfig()]).then(([userInfo, appId, salerConfig]) => {
            const { name, corporationName, userId } = userInfo.data;
            dispatch(
                updateUserInfo({
                    name,
                    avatar: '',
                    enterpriseName: corporationName,
                    userId,
                    wxAppId: appId.data,
                }),
            );
            dispatch(updateSalerConfig(salerConfig.data));
            const from = location.state?.from || { pathname: '', search: '' };
            const redirect =
                `${from?.pathname}${from?.search}` ||
                `${defaultAsyncRoute.path}${defaultAsyncRoute.query ? `?${defaultAsyncRoute.query}` : ''}`;
            navigate(redirect, { replace: true });
        });
    }

    function handleLogin() {
        form.validateFields().then((model) => {
            getSystemTokenByAccount(model.account).then((res) => {
                logined(res.data);
            });
        });
    }

    const LoginAccount = (
        <>
            <Form form={form}>
                <Form.Item name="account" rules={[{ required: true, message: '账号不能为空' }]}>
                    <Input
                        size="large"
                        allowClear={true}
                        placeholder="请输入账号"
                        prefix={<UserOutlined className={styles['form-icon']} />}
                        onKeyUp={(e) => ['Enter', 'NumpadEnter'].includes(e.code) && handleLogin()}
                    />
                </Form.Item>
            </Form>
            <Button type="primary" onClick={handleLogin} className={styles['login-btn']}>
                登录
            </Button>
        </>
    );

    return (
        <div className={`flex ${styles.login}`}>
            <div className={`flex ${styles.main}`}>
                <div className={styles.banner}></div>
                <div className={styles.panel}>
                    <div className={`flex ${styles.header}`}>
                        <img src={imgLogo} alt="" className={styles.logo} />
                        <span className={styles.title}>{process.env.REACT_APP_NAME}</span>
                    </div>
                    <Tabs
                        defaultActiveKey="account"
                        centered={true}
                        items={[
                            {
                                label: '账号登录',
                                key: 'account',
                                children: LoginAccount,
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    );
}
