import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useMount } from 'ahooks';
import { Layout } from 'antd';
import { PoweroffOutlined } from '@ant-design/icons';

import token from '../../utils/token';
import { useWxAgentConfig } from '../../hooks';

import styles from './index.module.less';
import imgAvatar from '../../assets/images/default-avatar.png';

import type { State } from '../../store/types';

export default function Header() {
    const userInfo = useSelector<State, State['userInfo']>((state) => state.userInfo);
    const wxAgentConfig = useWxAgentConfig();
    const openDataRef = useRef<any>();

    function handleLogout() {
        token.remove();
        window.location.reload();
    }
    useMount(() => {
        wxAgentConfig().then(() => {
            window.WWOpenData.bind(openDataRef.current);
        });
    });

    return (
        <Layout.Header className={`flex ${styles.header}`}>
            <div className={styles.left}>{userInfo.enterpriseName}</div>
            <div className={`flex ${styles.right}`}>
                <img src={userInfo.avatar || imgAvatar} alt="" className={styles.avatar} />
                <span className={styles.name}>
                    {userInfo.name ? (
                        userInfo.name
                    ) : (
                        <ww-open-data ref={openDataRef} type="userName" openid={userInfo.userId}></ww-open-data>
                    )}
                </span>
                <PoweroffOutlined className={styles['logout-btn']} onClick={handleLogout} />
            </div>
        </Layout.Header>
    );
}
