/**
 * 解析国际化语言, 将多层级的对象转化为单层对象
 * @param localeObj 语言对象
 * @param key 使用时不用传
 * @returns
 */
export function resolveLocale(localeObj: any, key?: string) {
    let obj = {} as any;
    for (const k in localeObj) {
        if (Object.prototype.hasOwnProperty.call(localeObj, k)) {
            const v = localeObj[k];
            const newKey = `${key ? `${key}.${k}` : k}`;
            if (Object.prototype.toString.call(v) === '[object Object]') {
                obj = { ...obj, ...resolveLocale(v, newKey) };
            } else {
                obj[newKey] = v;
            }
        }
    }

    return obj;
}

export default { resolveLocale };
