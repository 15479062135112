import { resolveLocale } from './utils';

import localeZh from './locale/zh';
import localeEn from './locale/en';

import { LocaleMap } from './types';

export const zh = resolveLocale({
    ...localeZh,
});
export const en = resolveLocale({
    ...localeEn,
});

const localeMap: LocaleMap<typeof zh> = {
    zh,
    en,
} as const;

export type LocaleKey = keyof typeof localeMap;

export default localeMap;
