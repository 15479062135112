import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

type Query<T> = string | T;

interface SearchParams<T = Record<string, any>> extends URLSearchParams {
    toObject: () => T;
}

function toQueryObject(query = '') {
    const obj: Record<string, any> = {};
    const _query = `${query.startsWith('?') ? '' : '?'}${query}`;
    const arr = _query.match(/[?|&][^&=]+=[^&=]+/g);
    if (Array.isArray(arr)) {
        for (let i = 0; i < arr.length; i += 1) {
            const param = arr[i].slice(1);
            obj[param.split('=')[0]] = decodeURIComponent(param.split('=')[1]);
        }
    }
    return obj;
}

export default function useURLQuery<T = Record<string, any>>(): [SearchParams<T>, (query: Query<T>) => void] {
    const [searchParams, setSearchParams] = useSearchParams();
    const _searchParams = useMemo(() => {
        Reflect.defineProperty(Object.getPrototypeOf(searchParams), 'toObject', {
            configurable: true,
            enumerable: false,
            writable: false,
            value() {
                return toQueryObject(this.toString());
            },
        });
        return searchParams;
    }, [searchParams]);

    function setQuery(query: Query<T>) {
        let obj: any = query;
        if (typeof query === 'string') {
            const queryArr = query.split('?');
            const queryStr = queryArr[queryArr.length === 1 ? 0 : 1];
            const [key, val] = queryStr.split('=');
            obj = key ? { [key]: val } : {};
        }

        return setSearchParams((old) => {
            const oldObj = toQueryObject(old.toString());
            Object.entries(obj).forEach(([key, val]: [string, any]) => {
                if ([undefined, null, ''].includes(val)) {
                    Reflect.deleteProperty(oldObj, key);
                } else {
                    oldObj[key] = val;
                }
            });

            return oldObj;
        });
    }

    return [_searchParams as SearchParams<T>, setQuery];
}
