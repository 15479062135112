import { Result } from './types';

export default function getErrMsg(res: Result) {
    switch (res.code) {
        case -1:
            return '程序开小差了, 请稍后再试';
        case 1008:
            return '登录失效, 请重新登录';
        case 3045:
            return '图片文件与后缀名不符，请另存为保存图片';
        case 9999: // 特定的code，直接返回msg
        default:
            return res.msg;
    }
}
