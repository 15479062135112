import { useRef, useEffect } from 'react';

export default function usePrevious<T = any>(value: T) {
    const old = useRef<T>();

    useEffect(() => {
        old.current = value;
    }, [value]);

    return old.current;
}
