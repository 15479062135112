import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'loading',
    initialState: false,
    reducers: {
        toggleLoading(state, { payload }: { payload: boolean }) {
            return payload;
        },
    },
});

export const { toggleLoading } = slice.actions;

export default slice.reducer;
