import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import type { MenuProps } from 'antd/es/menu';
import type { SubMenuType } from 'antd/es/menu/interface';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import { useRoutePath } from './hooks';

import styles from './index.module.less';

import type { RouteInfo } from '../../router/types';

interface Props {
    routeTree: RouteInfo[];
    routeLink: RouteInfo[];
}

type MenuItem = Required<MenuProps>['items'][number];

function checkRouteInMenu(menu: MenuItem, route?: RouteInfo): MenuItem | null {
    if (!route) return null;

    if ((menu as SubMenuType).children) {
        for (let i = 0; i < (menu as SubMenuType).children.length; i += 1) {
            const curr = (menu as SubMenuType).children[i];
            if (curr?.key === route.path) return curr;
            if ((curr as SubMenuType).children) {
                const _curr = checkRouteInMenu(curr, route);
                if (_curr) return _curr;
            }
        }
    }

    return null;
}

function findSelectedMenu(menu: MenuItem, route?: RouteInfo): MenuItem | null {
    if (!route) return null;

    if (menu?.key === route.path) return menu;

    const curr = checkRouteInMenu(menu, route);
    if (curr) return curr;

    // == 当前节点不符合就替换成当前节点的父节点再匹配
    const parent = findSelectedMenu(menu, route.parent);
    if (parent) return parent;

    return null;
}

function checkAllChildVisible(list: RouteInfo[]) {
    let allHide = true;
    for (let i = 0; i < list.length; i += 1) {
        const item = list[i];
        if (!item.hidden) {
            allHide = false;
            break;
        }
    }
    return allHide;
}

function getCurrRoute(route: RouteInfo): [RouteInfo | null, boolean] {
    if (route.hidden) {
        return [null, false];
    }
    let curr = route;
    let hasSubMenu = false;
    // 有子路由, 且最少有一个子路由显示的
    if (route.children && route.children.length > 0 && !checkAllChildVisible(route.children)) {
        // 只有一个子路由且不总显示
        if (!route.alwaysShow && route.children.length === 1) {
            // 子路由不隐藏，就默认将子路由替换当前路由显示
            if (!route.children[0].hidden) {
                curr = route.children[0];
            }
        } else {
            // 变成子导航菜单
            hasSubMenu = true;
        }
    }

    return [curr, hasSubMenu];
}

function createMenuItems(routes: RouteInfo[]) {
    const items: MenuItem[] = [];
    for (let i = 0; i < routes.length; i += 1) {
        const route = routes[i];
        const [current, hasSubMenu] = getCurrRoute(route);
        if (current) {
            const item = {
                key: current.path,
                icon: current.icon,
                children: hasSubMenu && current.children ? createMenuItems(current.children) : undefined,
                label: hasSubMenu ? (
                    current.title
                ) : (
                    <Link to={`${current.path}${current.query ? `?${current.query}` : ''}`}>{current.title}</Link>
                ),
            };
            items.push(item);
        }
    }
    return items;
}

export default function Sider(props: Props) {
    const { routeTree, routeLink } = props;
    const currRoutePath = useRoutePath();
    const [collapsed, setCollapsed] = useState(true);
    const CollapsedIcon = useMemo(() => (collapsed ? MenuUnfoldOutlined : MenuFoldOutlined), [collapsed]);
    const menus = useMemo(() => createMenuItems(routeTree), [routeTree]);
    const selected = useMemo(() => {
        const currRoute = routeLink.find((r) => r.path === currRoutePath);
        let currMenu;
        if (currRoute) {
            const { root } = currRoute;
            const rootMenu = menus.find((m) => m?.key === root?.path);
            if (rootMenu) {
                // == 从当前路由的根菜单匹配左侧栏选中的的节点
                currMenu = findSelectedMenu(rootMenu, currRoute);
            }
        }
        return [(currMenu?.key as string) || ''];
    }, [currRoutePath, routeLink, menus]);

    return (
        <Layout.Sider trigger={null} collapsible={true} collapsed={collapsed} className={styles.sider}>
            <div className={`flex ${styles.toolbar}`}>
                <CollapsedIcon onClick={() => setCollapsed(!collapsed)} />
            </div>
            <div className={styles.menu}>
                <Menu mode="inline" selectedKeys={selected} items={menus} />
            </div>
        </Layout.Sider>
    );
}
