import React, { useMemo, useEffect, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { ConfigProvider, Spin } from 'antd';
import dayjs from 'dayjs';

import { IntlProvider } from 'react-intl';
import antdZhCN from 'antd/locale/zh_CN';
import antdEnUS from 'antd/locale/en_US';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/en';
import localeMap from './i18n';

import routers from './router';

import type { State } from './store/types';
import type { LocaleMap } from './i18n/types';

const antdLocaleMap: LocaleMap<typeof antdZhCN> = {
    zh: antdZhCN,
    en: antdEnUS,
};

const dayLocaleMap: LocaleMap<string> = {
    zh: 'zh-cn',
    en: 'en',
};

export default function App() {
    const locale = useSelector<State, State['locale']>((state) => state.locale);
    const loading = useSelector<State, State['loading']>((state) => state.loading);
    const intlMsg = useMemo(() => localeMap[locale], [locale]);
    const antdMsg = useMemo(() => antdLocaleMap[locale], [locale]);

    useEffect(() => {
        dayjs.locale(dayLocaleMap[locale]);
    }, [locale]);

    return (
        <Suspense fallback={<div />}>
            <IntlProvider locale={locale} messages={intlMsg}>
                <ConfigProvider locale={antdMsg}>
                    <Spin spinning={loading} size="large">
                        <RouterProvider router={routers} />
                    </Spin>
                </ConfigProvider>
            </IntlProvider>
        </Suspense>
    );
}
