import React from 'react';
import { createBrowserRouter, RouteObject } from 'react-router-dom';

import RouteGuard from './RouteGuard';

import constantRoutes from './routes/constantRoutes';
import asyncRoutes from './routes/asyncRoutes';
import { processAsyncRoutes } from './utils';

import { RouteInfo } from './types';

const allRoutes = constantRoutes.reduce((acc, cur) => {
    if (cur.path === '/') {
        return [...acc, { ...cur, children: [...processAsyncRoutes(asyncRoutes, '/')] }];
    }
    return [...acc, cur];
}, [] as RouteInfo[]);
const index = allRoutes.findIndex((route) => route.path === '/404');
// 取出404页面，为了放在路由列表的最后
const route404 = index === -1 ? null : allRoutes.splice(index, 1)[0];
const redirectRoute = allRoutes.find((route) => route.redirectRoute);
const redirectPath = redirectRoute?.path || allRoutes[0].path;

function createRouterList(list: RouteInfo[]): RouteObject[] {
    return list.map((route) => {
        const PageComponent = route.component || (() => <></>);
        return {
            id: route.id,
            path: route.path,
            element: (
                <RouteGuard redirectPath={redirectPath}>
                    <PageComponent />
                </RouteGuard>
            ),
            children: route.children && route.children.length > 0 ? createRouterList(route.children) : [],
        };
    });
}

const routerList = createRouterList(allRoutes);
if (route404 && route404.component) {
    const PageComponent = route404.component;
    routerList.push({
        path: '*',
        element: <PageComponent />,
    });
}

export default createBrowserRouter(routerList, { basename: process.env.PUBLIC_URL });
