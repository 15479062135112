import React from 'react';
import { Image } from 'antd';

import styles from './index.module.less';
import img404 from '../../assets/images/404.gif';

export default function Page404() {
    return (
        <div className={`flex ${styles.page404}`}>
            <Image src={img404} preview={false} />
        </div>
    );
}
